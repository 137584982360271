<template>
  <article class="page cart">
    <div class="content-con">
      <h2 class="page-tit">
        <em>购物车</em
        ><span
          >选购产品<em class="count spancc">{{ cartDicts.length }}</em></span
        >
      </h2>

      <section class="forms-basic cart-recipient">
        <dl class="col2">
          <dt>收件人<em class="must">*</em></dt>
          <dd>
            <input
              type="text"
              class="form-control"
              id="inputName"
              placeholder="请填入收件人姓名"
              required
              v-model="BuyerInfo.Name"
            />
          </dd>
          <dt>收件人手机号<em class="must">*</em></dt>
          <dd>
            <input
              type="text"
              class="form-control"
              id="inputPhone"
              placeholder="请填入收件人手机号"
              required
              v-model="BuyerInfo.Phone"
            />
          </dd>
        </dl>

        <dl>
          <dt>收件人地址<em class="must">*</em></dt>
          <dd>
            <div id="post" class="citys">
              <select
                id="SelectListProvince"
                class="form-control"
                v-model="BuyerInfo.ProvinceId"
                v-on:change="ChangeProvince()"
              ></select>
              <select
                id="SelectListCity"
                class="form-control"
                v-model="BuyerInfo.CityId"
                v-on:change="ChangeCity()"
              ></select>
              <select
                id="SelectListDistrict"
                class="form-control"
                v-model="BuyerInfo.DistrictId"
                v-on:change="
                  ChangeDistrict();
                  FillAddress();
                "
              ></select>
              <select
                id="SelectListStreet"
                class="form-control"
                v-model="BuyerInfo.StreetId"
                v-on:change="ChangeStreet()"
              ></select>
              <input
                type="text"
                class="form-control"
                id="inputAddress"
                placeholder="请填入地址"
                v-model="BuyerInfo.Address"
              />
            </div>
          </dd>
          <dt>备注</dt>
          <dd>
            <input id="inputRemark" rows="2" v-model="BuyerInfo.Remark" />
          </dd>
        </dl>
      </section>

      <!-- Shopping Cart table -->
      <section class="cart-order">
        <table class="forms-basic tab-basic tab-cart">
          <!-- Table head -->
          <thead>
            <tr>
              <th class="td-img">图片</th>
              <th class="td-prod">商品</th>
              <th>单价</th>
              <th class="td-count">数量</th>
              <th>小计</th>
              <th class="td-action">&nbsp;</th>
              <th></th>
            </tr>
          </thead>
          <!-- /.Table head -->
          <!-- Table body -->
          <tbody id="tbOrder">
            <!--購物車裡有商品時的畫面-->
            <!-- First row -->
            <tr v-for="(item, key) in cartDicts" :key="key">
              <td class="td-img">
                <img :src="item.imgUrl" alt="" class="img-fluid z-depth-0" />
              </td>
              <td class="td-prod" data-th="商品">
                <p class="td-name">{{ item.title }}</p>
              </td>
              <td class="" data-th="单价">
                <!--<span class="ico_cgpCount">{{ item.price }}</span>-->
                <span class="ico_cgpCount">{{ item.price }}</span>
              </td>
              <td class="td-count" data-th="数量">
                <div class="count-bar">
                  <el-input-number
                    size="small"
                    v-model="item.buyQuantity"
                    @change="CalculatePrice"
                    :min="1"
                    :max="item.storageQuantity"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </td>
              <td class="" data-title="小计">
                <span class="ico_cgpCount">{{
                  item.price * item.buyQuantity
                }}</span>
              </td>
              <td class="td-action">
                <el-tooltip
                  popper-class="atooltip"
                  content="删除"
                  placement="top"
                >
                  <a
                    type="button"
                    class="btn btn-sm btn-delete"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="删除品项"
                    v-on:click="DeleteCartItem(item.sessionId)"
                  >
                    <i class="icon-trash"></i>
                  </a>
                </el-tooltip>
              </td>
            </tr>
            <!-- /.First row -->
            <!--/購物車裡有商品時的畫面-->
            <!--購物車為空時所顯示的畫面-->
            <tr v-if="cartItems.length == 0">
              <td colspan="6">
                <h2 class="text-center">购物车里没有物品喔!快去买吧!</h2>
              </td>
            </tr>
            <!--/購物車為空時所顯示的畫面-->
          </tbody>
          <!-- /.Table body -->
        </table>
      </section>
      <section class="cart-total">
        <table class="forms-basic tab-total">
          <tr class="text-right">
            <th>
              <span class="td-item">总计</span>
            </th>
            <td class="td-total td-price">
              <span class="ico_cgpDiscount td-total" id="lbProdTPrice">{{
                totalPrice
              }}</span>
            </td>
          </tr>
        </table>
      </section>
      <section class="cart-action checkout">
        <!--此提示字為幣值選擇BTC或ETH時才會跳出-->
        <i class="fas fa-exclamation-circle"></i>
        <!--<h6>
          注意:你现在所见的币值不代表最终币值，请详细确认后再行下单；若欲使用CGP币以外货币结帐，总价须超过一定金额：<br />
        </h6>-->
        <!--/此提示字為幣值選擇BTC或ETH時才會跳出-->
        <div class="text-right" style="overflow-x: hidden">
          <!--<button
                            type="button"
                            class="btn-buy"
                            v-on:click="PlaceOrder('CGP')"
                          >
                            我要结算(<span>CGP</span>)
                          </button>-->

          <!--<h5>
            <el-select v-model="paymentType" placeholder="请选择">
              <el-option
                v-for="item in paymentTypeArray"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </h5>-->

          <!--购买流程新增modal画面-->
          <button
            type="button"
            class="btn-action"
            @click="PlaceOrder()"
            :disabled="totalPrice == 0"
          >
            建立订单
          </button>

          <!--/购买流程新增modal画面-->
          <!--<button type="button"
                                  class="btn-buy btn-buy-limitation"
                                  v-on:click="PlaceOrder('USDT')">
                              我要结算(<span>USDT</span>)
                          </button>
                          <button type="button"
                                  class="btn-buy btn-buy-limitation"
                                  v-on:click="PlaceOrder('BTC')">
                              我要结算(<span>BTC</span>)
                          </button>
                          <button type="button"
                                  class="btn-buy btn-buy-limitation"
                                  v-on:click="PlaceOrder('ETH')">
                              我要结算(<span>ETH</span>)
                          </button>-->
        </div>
        <!-- /.Shopping Cart table -->
      </section>
    </div>
    <el-dialog
      title="订单已建立"
      :visible.sync="dialogVisible"
      width="30%"
      class="text-left coin-modal"
      @close="RedirectToIndex()"
      :modal-append-to-body="false"
    >
      <p class="prompt">
        您的订单已建立，请前往付款。 <br />已建立的订单可经由手机号查询。
      </p>
      <h5>支付币种</h5>
      <p>{{ paymentType }}币</p>
      <h5>即将支付金额</h5>
      <p v-if="paymentType == 'CGP'">{{ totalPrice }} CGP</p>
      <p v-else>
        {{ totalPriceTransformed }} {{ paymentType }} ，約{{ totalPrice }} CGP
      </p>
      <span slot="footer" class="dialog-footer">
        <button type="button" class="btn-buy" @click="OpenPaymentWindow()">
          前往付款
        </button>
      </span>
    </el-dialog>
    <div
      class="modal fade cart-coin"
      id="Coin_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="check-message"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title font-weight-bold" id="check-message">
              订单已建立
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-left">
            <p class="prompt">
              您的订单已建立，请前往付款。 <br />已建立的订单可经由手机号查询。
            </p>
            <h5>支付币种</h5>
            <p>{{ paymentType }}币</p>
            <h5>即将支付金额</h5>
            <p v-if="paymentType == 'CGP'">{{ totalPrice }} CGP</p>
            <p v-else>
              {{ totalPriceTransformed }} {{ paymentType }} ，約{{ totalPrice }}
              CGP
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn-action"
              @click="OpenPaymentWindow()"
            >
              前往付款
            </button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<style scoped>
    .ico_cgpCount {
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 14px;
        background-image: url(/assets/img/icons-CNY.svg);
        padding-left: 1.2em;
    }
    .ico_cgpDiscount {
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 16px;
        background-image: url(/assets/img/icons-CNY.svg);
        padding-left: 1em;
    }
/*		.td-price:before {
			content: "" !important;
		}*/
.checkout i {
  color: #e43838;
}
.checkout h6 {
  font-weight: bold;
  color: #e43838;
  display: inline-block;
  line-height: 1.5;
  margin-bottom: 10px;
}
.cart-coin .modal-body .prompt {
  color: #e43838;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 700;
}
</style>
<script>
import {
  GetProductSpecByProdSpecId,
  GetProductById,
  GetProductSpecByProdId,
  GetTownList,
  PlaceOrder,
  CheckIsProductCanBuy,
} from "@/service/api";
import { crytpoLowLimit, baseUrl, merchantId } from "@/service/config";
export default {
  data() {
    return {
      BuyerInfo: {
        Name: "",
        Phone: "",
        Province: "",
        ProvinceId: "",
        City: "",
        CityId: "",
        District: "",
        DistrictId: "",
        Street: "",
        StreetId: "",
        Address: "",
        Remark: "",
      },
      dialogVisible: false,
      cartItems: [],
      cartDicts: [],
      totalPrice: 0,
      paymentType: "",
      paymentUrl: "",
      limitstr: "",
      totalPriceTransformed: 0,
    };
  },
  beforeMount: function () {},

  mounted: function () {
    this.$parent.SetWindowTitle("购物车");
    this.GetCartItems();
    this.GetCartDicts();
    this.AddressInit();
    $("#Coin_modal").on("hidden.bs.modal", function () {
      window.location.href = "index";
    });
    //banner 設定尺寸
    $(".banner-cover").addClass("_lvSec");
  },
  updated: function () {
    this.CalculatePrice();
  },
  watch: {
    totalPrice: function () {
      if (this.totalPrice == 0) {
        this.paymentType = "";
      } else if (this.totalPrice < 300 && this.paymentType != "CGP") {
        this.paymentType = "";
      }
    },
  },
  computed: {
    paymentTypeArray() {
      return [
        {
          value: "CGP",
          label: "CGP",
        },
        {
          value: "USDT",
          label: "USDT",
          //disabled: this.totalPrice < 300 ? true : false,
        },
        {
          value: "BTC",
          label: "BTC",
          //disabled: this.totalPrice < 300 ? true : false,
        },
        {
          value: "ETH",
          label: "ETH",
          //disabled: this.totalPrice < 300 ? true : false,
        },
      ];
    },
  },
  methods: {
    handleChange(value) {},
    CalculatePrice() {
      this.UpdateCartItem();
      var totalprice = 0;
      this.cartDicts.forEach(function (item, inde, array) {
        totalprice += item.buyQuantity * item.price;
      });
      this.totalPrice = totalprice;
    },
    GetCartItems() {
      var cartItems = [];
      var keys = Object.keys(localStorage).filter((o) => o.startsWith("cart_"));
      $.each(keys, function (index, key) {
        cartItems.push(JSON.parse(localStorage.getItem(key)));
      });
      this.cartItems = cartItems;
    },
    async GetCartDicts() {
      var cartDicts = [];
      var cartDict = {};
      for await (const item of this.cartItems) {
        cartDict = {};
        cartDict["buyQuantity"] = item.Quantity;
        cartDict["sessionId"] = "cart_" + item.ProductId + item.ProductSpecId;
        await GetProductById(item.ProductId).then(async (resp) => {
          if (resp.data != null) {
            cartDict["sessionId"] = item.ProductId + item.ProductSpecId;
            cartDict["productId"] = item.ProductId;
            cartDict["productSpecId"] = item.ProductSpecId;
            cartDict["imgUrl"] = this.GetImageUrl(resp.data.MainImage, 271);
            cartDict["title"] = resp.data.Title;
            cartDict["price"] = resp.data.Price;
            await GetProductSpecByProdSpecId(item.ProductSpecId).then(
              (detailresp) => {
                if (detailresp.data != null) {
                  cartDict["spec"] = detailresp.data.Name;
                  cartDict["storageQuantity"] = detailresp.data.Quantity;
                }
              }
            );
            cartDicts.push(cartDict);
          } else {
            alert("包含已下架／已删除商品，将自动移除。");
            /*alert("Deleting: "+"cart_"+ item.ProductId + item.ProductSpecId);*/
            this.DeleteCartItem(item.ProductId + item.ProductSpecId);
          }
        });
      }
      this.cartDicts = cartDicts;
    },

    GetImageUrl(imgId, size) {
      var d = new Date();
      var n = d.getTime();
      return (
        "https://n2imageservice.azurewebsites.net/api/images/shopmall/" +
        imgId +
        "/" +
        size +
        "/0?" +
        n
      );
    },

    AddressInit() {
      var provinceblacklist = [
        "150000000000",
        "450000000000",
        "540000000000",
        "630000000000",
        "640000000000",
        "650000000000",
      ];
      $.getJSON("../../assets/ChinaAddr/province.json", function (data) {
        $("#SelectListProvince")
          .empty()
          .append($("<option></option>").val("").text("------"));
        $.each(data, function (i, item) {
          if (!provinceblacklist.includes(item.id))
            $("#SelectListProvince").append(
              $("<option></option>").val(item.id).text(item.name)
            );
        });
      });
      $("#SelectListCity")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $("#SelectListDistrict")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $("#SelectListStreet")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $("#inputAddress").text("");
      //   $("#SelectListProvince").change(function () {
      //     ChangeProvince(this.value);
      //   });
      //   $("#SelectListCity").change(function () {
      //     this.ChangeCity(this.value);
      //   });
      //   $("#SelectListCounty").change(function () {
      //     this.ChangeCounty(this.value);
      //     FillAddress();
      //   });
      //   $("#SelectListTown").change(function () {
      //     FillAddress();
      //   });
    },

    ChangeProvince() {
      this.BuyerInfo.Province = $("#SelectListProvince option:selected").text();
      $("#SelectListCity")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $("#SelectListDistrict")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $("#SelectListStreet")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $.getJSON("../../assets/ChinaAddr/city.json", function (data) {
        $("#SelectListCity")
          .empty()
          .append($("<option></option>").val("").text("------"));
        var selectCitys = data[$("#SelectListProvince option:selected").val()];
        $.each(selectCitys, function (i, item) {
          $("#SelectListCity").append(
            $("<option></option>").val(item.id).text(item.name)
          );
        });
      });
    },
    ChangeCity() {
      var ref = this;
      this.BuyerInfo.City = $("#SelectListCity option:selected").text();
      $("#SelectListDistrict")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $("#SelectListStreet")
        .empty()
        .append($("<option></option>").val("").text("------"));
      $.getJSON("../../assets/ChinaAddr/county.json", function (data) {
        $("#SelectListDistrict")
          .empty()
          .append($("<option></option>").val("").text("------"));
        var selectCounty = data[$("#SelectListCity option:selected").val()];
        if (selectCounty != null) {
          $.each(selectCounty, function (i, item) {
            $("#SelectListDistrict").append(
              $("<option></option>").val(item.id).text(item.name)
            );
          });
        } else {
          ref.ChangeDistrictByCityId(
            $("#SelectListCity option:selected").val()
          );
        }
      });
    },

    ChangeDistrict() {
      this.BuyerInfo.District = $("#SelectListDistrict option:selected").text();
      $("#SelectListStreet")
        .empty()
        .append($("<option></option>").val("").text("------"));

      GetTownList($("#SelectListDistrict option:selected").val()).then(
        (resp) => {
          $.each(resp.data, function (i, item) {
            $("#SelectListStreet").append(
              $("<option></option>").val(item.id).text(item.name)
            );
          });
        }
      );
    },
    ChangeDistrictByCityId(cityId) {
      this.BuyerInfo.District = $("#SelectListDistrict option:selected").text();
      $("#SelectListStreet")
        .empty()
        .append($("<option></option>").val("").text("------"));

      GetTownList(cityId).then((resp) => {
        $.each(resp.data, function (i, item) {
          $("#SelectListStreet").append(
            $("<option></option>").val(item.id).text(item.name)
          );
        });
      });
    },
    ChangeStreet() {
      this.BuyerInfo.Street = $("#SelectListStreet option:selected").text();
      this.FillAddress();
    },
    FillAddress() {
      var addressText = "";
      //if ($('#SelectListProvince')[0].selectedOptions[0].text != "------")
      //    addressText += $('#SelectListProvince option:selected').text();
      //if ($('#SelectListCity')[0].selectedOptions[0].text != "------")
      //    addressText += $('#SelectListCity option:selected').text();
      if ($("#SelectListDistrict")[0].selectedOptions[0].text != "------")
        addressText += $("#SelectListDistrict option:selected").text();
      if ($("#SelectListStreet")[0].selectedOptions[0].text != "------")
        addressText += $("#SelectListStreet option:selected").text();
      this.BuyerInfo.Address = addressText;
      //   $("#inputAddress").val(addressText);
    },
    UpdateCartItem: function () {
      $.each(this.cartDicts, function (key, value) {
        var forCartId = "cart_" + value.productId + value.productSpecId;
        var oldData = JSON.parse(localStorage.getItem(forCartId));
        if (oldData != null) {
          if (value.buyQuantity == "" || value.buyQuantity < 1) {
            value.buyQuantity = 1;
            alert("最小購買量為1個");
          }
          if (oldData.Quantity != value.buyQuantity) {
            if (value.buyQuantity <= value.storageQuantity) {
              oldData.Quantity = value.buyQuantity;
            } else {
              oldData.Quantity = value.storageQuantity;
              value.buyQuantity = value.storageQuantity;
              alert("庫存不足！");
            }
            localStorage.setItem(forCartId, JSON.stringify(oldData));
          }
        }
      });
      this.$parent.RefreshCart();
    },
    DeleteCartItem(id) {
      localStorage.removeItem("cart_" + id);
      this.$parent.RefreshCart();
      this.GetCartItems();
      this.GetCartDicts();
      this.CalculatePrice();
    },
    // async IsProductSpecCanBuy(specId, Qvt) {
    //   await CheckIsProductCanBuy(specId, Qvt).then((result) => {
    //     return result;
    //   });
    // },
    IsProductSpecCanBuyAjax(specId, Qvt) {
      var result = false;
      $.ajax({
        url:
          baseUrl +
          "/api/Order/CheckIsProductCanBuy?merchantId=" +
          merchantId +
          "&productSpecId=" +
          specId +
          "&qvt=" +
          Qvt,
        type: "Get",
        async: false,
        success: function (response) {
          result = response;
        },
      });
      return result;
    },
    CalculateRatePrice(crypoType, cgpAmount) {
      var rate = parseFloat(this.GetRate(crypoType));
      var targetPrice = (cgpAmount * 10000000) / rate;
      return targetPrice / 10000000;
    },
    GetRate(crypoType) {
      var number = 0.0;
      $.ajax({
        url: baseUrl + "/api/Rate/GetAllRate",
        type: "POST",
        dataType: "json",
        async: false,
        data: {
          sToken: "DDlnHbZAA6KGp0Y4LGz9",
        },
        success: function (rateTable) {
          switch (crypoType) {
            case "USDT":
              number = rateTable.PairsPrice.USDT_RMB.SellPrice;
              break;
            case "ETH":
              number =
                rateTable.PairsPrice.USDT_RMB.SellPrice *
                rateTable.PairsPrice.ETH_USDT.SellPrice;
              break;
            case "BTC":
              number =
                rateTable.PairsPrice.USDT_RMB.SellPrice *
                rateTable.PairsPrice.BTC_USDT.SellPrice;
              return number;
            default:
              number = -1;
          }
        },
      });
      return number;
    },
    PlaceOrder() {
      let $vm = this;
      this.GetCartItems(); //Update From localStorage
      this.GetCartDicts();
      var errorstring = "";
      if ($("#inputName").val() == "") errorstring += "未输入姓名。" + "\n";
      if ($("#inputPhone").val() == "") errorstring += "未输入电话。" + "\n";
      if ($("#inputAddress").val() == "") errorstring += "未输入地址。" + "\n";
      if (this.cartDicts.length == 0) errorstring += "购物车无资料。";
      if (this.paymentType == "") errorstring += "请选择结帐币别。";
      // if (this.paymentType != "CGP" && this.totalPrice < 300)
      //   errorstring += "请重新选择结帐币别，总价未超过300CGP币。";
      if (this.paymentType != "CGP") {
        var translatedPrice =
          Math.round(
            this.CalculateRatePrice(this.paymentType, this.totalPrice) * 10000
          ) / 10000;
        this.totalPriceTransformed = translatedPrice;
        if (translatedPrice < crytpoLowLimit[this.paymentType]) {
          errorstring +=
            "此币别(" +
            this.paymentType +
            ")之最低结帐金额为" +
            crytpoLowLimit[this.paymentType] +
            "，您的结帐金额约为" +
            translatedPrice +
            "元。";
        }
      }
      if (errorstring == "") {
        var itemInfo = [];
        itemInfo.push(this.cartDicts);
        var cartDetail = [];
        $.each(this.cartDicts, function (index, item) {
          if (
            $vm.IsProductSpecCanBuyAjax(item.productSpecId, item.buyQuantity)
          ) {
            var cartDict = {
              productId: item.productId,
              productSpecId: item.productSpecId,
              productName: item.title,
              productSpecName: item.spec,
              price: item.price,
              storageQuantity: item.storageQuantity,
              BuyQuantity: item.buyQuantity,
              totalPrice: parseFloat(item.price) * parseInt(item.buyQuantity),
            };
            cartDetail.push(cartDict);
          } else {
            alert(
              item.title +
                " - " +
                item.spec +
                " 已无法购买，将由此订单删除。请重新回到商品页面确认数量及是否下架。"
            );
            this.DeleteCartItem(item.sessionId);
          }
        });

        if (cartDetail.length > 0) {
          var orderInfo = {
            BuyerName: $("#inputName").val(),
            BuyerMobile: $("#inputPhone").val(),
            BuyerAddress: $("#inputAddress").val(),
            BuyerRemark: $("#inputRemark").val(),
            BuyerAddressProvinceCode: $(
              "#SelectListProvince option:selected"
            ).val(),
            BuyerAddressCityCode: $("#SelectListCity option:selected").val(),
            BuyerAddressCountyCode: $(
              "#SelectListDistrict option:selected"
            ).val(),
            BuyerAddressTownCode: $("#SelectListStreet option:selected").val(),
            BuyerAddressProvince: $(
              "#SelectListProvince option:selected"
            ).text(),
            BuyerAddressCity: $("#SelectListCity option:selected").text(),
            BuyerAddressCounty: $("#SelectListDistrict option:selected").text(),
            BuyerAddressTown: $("#SelectListStreet option:selected").text(),
            Items: cartDetail,
            PaymentType: $vm.paymentType,
          };
          var string = JSON.stringify(orderInfo);
          // alert(string);
          PlaceOrder(orderInfo).then((resp) => {
            if (String(resp.data).startsWith("http")) {
              $vm.paymentUrl = resp.data;
              localStorage.clear();
              this.$parent.RefreshCart();
              // $vm.dialogVisible = true;
              $("#Coin_modal").modal("toggle");
            } else {
              alert("订单产生失败，" + "\n" + resp.data);
            }
          });
        } else {
          alert("购物车未有可购买物品，请重新选购。");
        }
      } else alert(errorstring);
    },
    OpenPaymentWindow() {
      var win = window.open(this.paymentUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("請允许网站显示弹出式窗口。");
      }
    },
    RedirectToIndex() {
      window.location.href = "/";
    },
  },
};
</script>